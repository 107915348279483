
import { FileNameDto} from "@/types/FileNameDto";

export default {
  name: "QualifyFilesModal",
  methods: {
    close() {
      this.show = false;
    },
    downloadFile(name) {
      this.axios.get(`${process.env.VUE_APP_BACKEND_BASE_URL}/files/qualify/${this.fileItem.id}`,
          {responseType:"blob"})
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;

            let expression = "";
            switch (response.headers["content-type"]) {
              case "image/png":
                expression = '.png';
                break;
              case "image/jpg":
              case "image/jpeg":
                expression = '.jpg';
                break;
              case "application/pdf":
                expression = ".pdf";
                break;
            }

            link.setAttribute('download', `qual${expression}`);
            document.body.appendChild(link);
            link.click();
          });
    },
  },
  props: {
    value: Boolean,
    names: Array,
  },
  data() {
    return {
      fileItem: Object as () => FileNameDto,
    };
  },
  computed: {
    show: {
      get () {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        if (!this.fileName) this.fileItem = this.names[0];
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        else this.fileItem = this.names.find(t => t.name === this.fileItem.name);
        return this.value;
      },
      set (value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
